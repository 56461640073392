
    @media screen and (max-width: 425px) {
    .textContainerdesi h5 {

     font-size: 23px !important;
     padding-top:5px;
     text-align: left;
    } 
    .buttonContainerdesi button {
      text-align: center;
      padding-top:5px;
      font-size: 14px !important; 
    }
    }
    @media screen and (max-width: 375px) {
     
      .textContainerdesi h5 {

       font-size: 21px !important;
       padding-top:5px;
        text-align: left;
      } 
      .buttonContainerdesi button {
        text-align: center;
        font-size: 12px !important; 
        padding-top:5px;
      }   
    }
      @media screen and (max-width: 320px) {
     
        .textContainerdesi h5 {
     
         font-size: 18px !important;
         padding-top:5px;
          text-align: left;
        } 
        .buttonContainerdesi button {
          text-align: center;
          font-size: 10px !important; 
          padding-top:5px;
        }  
    }

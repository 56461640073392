.dashboardCard {
  padding: 15px;
  text-align: start;
}
.noDataFound {
  text-align: center;
  margin-top: 14px;
  color: #999;
}

/*----------- new dashboard style -------------*/

.bottomCard {
  border: 1px solid #f7f7f7;
  margin: 0 15px;
  box-shadow: 0px 4px 4px 4px #dedede;
  border-radius: 8px;
}
.header {
  background: #18285c;
  text-align: center;
  padding: 6px;
  color: #fff;
  border-radius: 8px 8px 0 0;
}
.content {
  min-height: 270px;
  overflow: auto;
  height: 270px;
  max-height: 270px;
  color: #676767;
}
.detailsContent {
  display: inline-flex;
  width: 50%;
  padding-left: 4px;
  color: #000000;
  white-space: break-spaces;
  text-align: end;
}
.oldDetailsContent {
  margin-right: auto;
  padding-left: 8px;
  color: red;
}

.listItem {
  padding-inline-start: 0em !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.borderStyle {
  border-radius: 3px;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
  /* padding-block-start: 0em;? */
  padding-inline-end: 0em !important;
  padding-block-end: 0em !important;
  min-inline-size: 0% !important;
  padding-top: 0px !important;
  margin-top: -10px;
}

.inquiryStatusCombo {
  height: 30px !important;
}

.PanelStyle {
  border-radius: 3px;
  width: 100%;
}

.listStyle {
  height: 225px;
}

.childPanelStyle {
  display: flex;
  flex-wrap: wrap;
}

.listItemText {
  padding-bottom: 10px;
  width: 100%;
  color: #000000;
  text-align: left;
  font-family: "Inter-Regular", sans-serif !important;
  font-size: 13px !important;
  font-weight: 400 !important;
  position: relative;
  display: flex;
  justify-content: space-between;
}


    @media (max-width: 425px) {
     .title {
       font-size: 25px !important; 
     }
   }
    @media (max-width: 375px) {
     .title {
       font-size: 21px !important; 
     }
   }
     @media (max-width: 320px) {
       .title {
         font-size: 17px !important; 
       }
     }


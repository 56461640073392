
           @media screen and (max-width: 425px) {
            .textContainer h5 {
             text-align:center;
             font-size: 17px !important;
             padding-top:10px;
             text-align: left;
            } 
        }
            .buttonContainer button {
              text-align: left;
              padding-top:10px;
              font-size: 10px; 
            }
    
            @media screen and (max-width: 375px) {
             
              .textContainer h5 {
               text-align:center; 
               font-size: 15px !important;
               padding-top:10px;
                text-align: left;
              } 
              .buttonContainer button {
                text-align: center;
                font-size: 10px; 
                padding-top:7px;
              } 
            }
          @media screen and (max-width: 320px) {
         
            .textContainer h5 {
             text-align:center; 
             font-size: 15px !important;
             padding-top:10px;
              text-align: left;
            } 
            .buttonContainer button {
              text-align: center;
              font-size: 8px !important; 
              padding-top:7px;
            }   
        }
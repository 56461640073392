
    @media (max-width: 425px) {
     .title {
       font-size: 27px !important; 
       text-align:right;
     }
   }
    @media (max-width: 375px) {
     .title {
       font-size: 23px !important; 
       text-align:right;
     }
   }
     @media (max-width: 320px) {
       .title {
         font-size: 18px !important; 
         text-align:right;
       }
     }



    @media screen and (max-width: 425px) {
    .textContainerfund h5 {

     font-size: 22px !important;
     padding-top:10px;
     text-align: left;
    } 
    .buttonContainerfund button {
      text-align: center;
      padding-top:10px;
      font-size: 13px!important; 
    }
}
    @media screen and (max-width: 375px) {
     
      .textContainerfund h5 {
      
       font-size: 20px !important;
       padding-top:10px;
        text-align: left;
      } 
      .buttonContainerfund button {
        text-align: center;
        font-size: 12px !important; 
        padding-top:7px;
      }  
    } 
      @media screen and (max-width: 320px) {
     
        .textContainerfund h5 {
         font-size: 18px !important;
         padding-top:10px;
          text-align: left;
        } 
        .buttonContainerfund button {
          text-align: center;
          font-size: 10px !important; 
          padding-top:7px;
        }   
        
    }

    @media screen and (max-width: 425px) {
    .textContainerinqstate h5 {
     font-size: 22px !important;
     padding-top:10px;
     text-align: left;
    } 
    .buttonContainerinqstate button {
      text-align: center;
      padding-top:10px;
      font-size: 13px !important; 
    }
    }
    @media screen and (max-width: 375px) {
     
      .textContainerinqstate h5 {
       font-size: 19px !important;
       padding-top:10px;
        text-align: left;
      } 
      .buttonContainerinqstate button {
        text-align: center;
        font-size: 11px !important; 
        padding-top:7px;
      }   
    }
      @media screen and (max-width: 320px) {
     
        .textContainerinqstate h5 {
         font-size: 16px !important;
         padding-top:10px;
          text-align: left;
        } 
        .buttonContainerinqstate button {
          text-align: center;
          font-size: 9px !important; 
          padding-top:7px;
        }   
       
    }

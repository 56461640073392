@media screen and (max-width: 768px) {
    .textContainerinqlist h5 {
     font-size: 24px !important;
     padding-top:7px;
     text-align: start;
    } 
    .buttonContainerinqlist1  {
      text-align: center;
      padding-top:10px;
      font-size: 12px !important; 
    }
    .buttonContainerinqlist2 {
        text-align: center;
        font-size: 12px  !important; 
        padding-top:10px ;
    
      }   
    
    }
    @media screen and (max-width: 425px) {
        .textContainerinqlist h5 {
         font-size: 18px !important;
         padding-top:5px;
         text-align: start;
        } 
        .buttonContainerinqlist1  {
            
          text-align: center;
          padding-top:5px;
          font-size: 9px !important; 
        }
        .buttonContainerinqlist2 {

            text-align: center;
            font-size: 9px  !important; 
            padding-top:5px ;
        
          }   
        
        }
    @media screen and (max-width: 375px) {
     
      .textContainerinqlist h5 {
       font-size: 14px !important;
       padding-top:10px;
        text-align: start;
      } 
      .buttonContainerinqlist1  {
        text-align: center;
        font-size: 9px !important; 
        padding-top:7px;
      }   
      .buttonContainerinqlist2 {
        text-align: center;
        font-size: 9px  !important; 
        padding-top:7px ;
    
      }   
    
    }
      @media screen and (max-width: 320px) {
     
        .textContainerinqlist h5 {
         font-size: 11px !important;
         padding-top:10px;
          text-align: start;
        } 
        .buttonContainerinqlist1 {
          text-align: center;
          font-size: 8px  !important; 
          padding-top:5px ;
        }   
        .buttonContainerinqlist2 {
            text-align: center;
            font-size: 8px  !important; 
            padding-top:5px ;
        
          }   
        
      
    }
 

    @media screen and (max-width: 425px) {
    .buttonContainercountry button {
      text-align: center;
      padding-top:5px;
      font-size: 14px !important; 
    }
    }
    @media screen and (max-width: 375px) {
      .buttonContainercountry button {
        text-align: center;
        font-size: 14px !important; 
        padding-top:7px;
      }   
    }
      @media screen and (max-width: 320px) { 
        .buttonContainercountry button {
          text-align: center;
          font-size: 12px !important; 
          padding-top:7px;
        }   
    }

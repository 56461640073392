@media screen and (max-width: 425px) {
  .textContainerWTD h5 {
    font-size: 17px !important;
    padding-top: 13px;
    text-align: left;
  }
  .buttonContainerWTD button {
    text-align: center;
    padding-top: 1px;
    font-size: 1px !important;
  }
  .iconButton {
    font-size: large !important;
    padding-top: 15px !important;
  }
}
@media screen and (max-width: 375px) {
  .textContainerWTD h5 {
    font-size: 15px !important;
    padding-top: 17px;
    text-align: left;
  }
  .buttonContainerWTD button {
    font-size: 8px !important;
    padding-top: 5px;
  }
  .iconButton {
    font-size: large !important;
    padding-top: 15px !important;
  }
}
@media screen and (max-width: 320px) {
  .textContainerWTD h5 {
    font-size: 13px !important;
    padding-top: 20px;
    text-align: left;
  }
  .buttonContainerWTD button {
    text-align: center;
    font-size: 6px !important;
    padding-top: 5px;
  }
  .iconButton {
    font-size: large !important;
    padding-top: 15px !important;
  }
}


    @media screen and (max-width: 425px) {
    .textContainerbltp h5 {
     text-align:center;
     font-size: 13px !important;
     padding-top:10px;
     text-align: left;
    } 
    .buttonContainerbltp button {
      text-align: center;
      font-size: 12px !important; 
      padding-top:7px;
    }
    }
    @media screen and (max-width: 375px) {
     
      .textContainerbltp h5 {
       text-align:center; 
       font-size: 12px !important;
       padding-top:10px;
        text-align: left;
      } 
      .buttonContainerbltp button {
        text-align: center;
        font-size: 6px !important; 
        padding-top:7px;
      }  
    } 
      @media screen and (max-width: 320px) {
     
        .textContainerbltp h5 {
         text-align:center; 
         font-size: 10px !important;
         padding-top:10px;
          text-align: left;
        } 
        .buttonContainerbltp button {
          text-align: center;
          font-size: 5px !important; 
          padding-top:7px;
        }   
      
        }

.darkMode .MuiInputLabel-root,
.darkMode .MuiInputBase-root.MuiOutlinedInput-root,
.darkMode .MuiAutocomplete-clearIndicator {
  color: #fff !important;
  font-size: 0.8rem !important;
}
.darkMode .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 4px !important;
  padding-bottom: 6px !important;
  padding-left: 4px !important;
}
.darkMode .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}
.darkMode .MuiInputBase-input.MuiOutlinedInput-input {
  padding: 5px 0 0px 4px;
}
.darkMode .MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
  color: #fff !important;
}
.darkMode .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

.customcard {
  gap: 5rem;

  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.card1 {
  border: 2px solid #1b0707;
  border-radius: 5px;
  display: flex;
  /* flex-direction: column; */
  /* gap:1rem; */
  /* height: 95px; */
}

.left {
  padding: 20px 20px;
  display: flex;
  align-items: center;
}
.dividerline {
  border-left: 2px solid #1b0707;
  margin: 8px 0;
}
.logo {
  height: 30px; /* Adjust the height as needed */
  background-image: url('../../assets/images/logo-home-png-7412.png');
  width: 30px !important;
  background-size: cover;
  background-position: center;
}
.title {
  /* height: 30px; */
  width: auto;
}
.description {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px;
}
.addicon {
  padding: 5px 5px 0 0;
  text-decoration: 'none';
  position: relative;
  left: 0;
  margin-right: 3px;
  margin-left: 0px;
  bottom: 3px;

  color: #000;
}

.addicon:hover {
  padding: 5px 5px 5px 5px;
  background-color: rgb(228, 226, 241);
  cursor: pointer;
  border: none;
  border-radius: 50%;
  align-items: center;
}

.moreHorizIcon {
  padding: 5px 5px 0 0;
  text-decoration: 'none';
  position: absolute;
  right: 0;
  text-decoration: none;
  color: #000;
}

.menuDropdown {
  border-radius: 3px;
  display: flex;
  position: absolute;
  right: 0;
  top: 25px;
  flex-direction: column;
  justify-content: end;
  width: 100px;
  box-shadow: 1px 1px 8px 2px rgb(79, 78, 78);
}

.button1 {
  font-size: 14px;
  padding: 10px;
  padding-left: 17px;
  background-color: #fff;
  cursor: pointer;
  border: none;
  border-radius: 4px;
}
.button1:hover {
  background-color: rgb(228, 226, 241);
}

.divider {
  margin: 0;
  border: none;
  height: 1px;
  background-color: #ccc;
}

.editicon {
  padding: 5px 5px 5px 0;
  text-decoration: 'none';
  position: absolute;
  left: 5px;
  text-decoration: none;
  color: #000;
  bottom: 40px;
  margin: auto;
}

.delicon {
  margin-right: 2px;
  padding: 5px 5px 5px 0;
  text-decoration: 'none';
  position: absolute;
  left: 4px;
  bottom: 1px;
  color: #000;
}


.stack{
  width: 200px;
}

@media screen and (max-width: 425px) {
  .textContainer1 h5 {
    font-size: 18px !important;
    padding-top: 5px;
    text-align: left;
  }
  .buttonContainer1 button {
    text-align: center;
    padding-top: 5px;
    font-size: 10px !important;
  }
}
@media screen and (max-width: 375px) {
  .textContainer1 h5 {
    font-size: 16px !important;
    padding-top: 5px;
    text-align: left;
  }
  .buttonContainer1 button {
    text-align: center;
    font-size: 8px !important;
    padding-top: 5px;
  }
}
@media screen and (max-width: 320px) {
  .textContainer1 h5 {
    font-size: 13px !important;
    padding-top: 7px;
    text-align: left;
  }
  .buttonContainer1 button {
    text-align: center;
    font-size: 6px !important;
    padding-top: 5px;
  }
}

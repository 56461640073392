@media (max-width: 375px) {
  .labelContainer {
    font-size: 19px !important;
  }
}
@media (max-width: 320px) {
  .labelContainer {
    font-size: 16px !important;
  }
}

.dropBox div {
  padding: 3.5px 14px !important;
  min-width: 42px;
  background-color: #e5e5e5;
}
.dropBox:focus-visible {
  outline: none !important;
}
.dropBox .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}

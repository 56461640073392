/* width */
.scrol::-webkit-scrollbar {
  width: 5px;
}

.scrol::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.scrol::-webkit-scrollbar-thumb {
  background: var(--theme-color);
}

.scrol::-webkit-scrollbar-thumb:hover {
  background: var(--theme-color);
}

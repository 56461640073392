.darkMode .MuiInputLabel-root {
  color: #fff !important;
}
.darkMode .MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
}
.darkMode .MuiSvgIcon-root.MuiSelect-icon {
  color: #fff !important;
}
.darkMode div#demo-select-small-select {
  color: #fff;
}
.darkMode .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
  padding: 3px !important;
}

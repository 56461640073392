@media screen and (max-width: 425px) {
  .dashboardTitle {
    font-size: 20px !important;
  }
  .profileTypo {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 375px) {
  .dashboardTitle {
    font-size: 16px !important;
  }
  .profileTypo {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 320px) {
  .dashboardTitle {
    font-size: 14px !important;
  }
  .profileTypo {
    font-size: 12px !important;
  }
}

.MuiButton-containedPrimary {
  background-color: var(--theme-color) !important;
  min-width: 100px !important;
  box-shadow: none !important;
  border: 1px solid var(--theme-color);
}
.MuiButtonBase-root.MuiButton-root {
  height: 40px;
}
.MuiButton-outlinedPrimary:hover {
  background-color: var(--theme-color-light) !important;
}
.MuiButton-outlinedPrimary {
  color: var(--theme-color) !important;
  border: 1px solid var(--theme-color) !important;
}
.logo {
  height: 150px;
}
.header {
  background-color: var(--black) !important;
  box-shadow: none !important;
  border-bottom: 1px solid #ffffff61 !important ;
}
.sideDrawer-container {
  background: var(--black);
  height: 100%;
  overflow: auto;
}
.sideDrawer-list {
  width: '100%';
  max-width: '360px';
  background: var(--black);
  color: #fff;
}
.sideDrawer-list .MuiListItemButton-root {
  border-bottom: 1px solid #ffffff61;
}
.sideDrawer-list .MuiListItemButton-root:hover {
  background-color: #d6e0ff69 !important;
}
.sideDrawer-list .MuiListItemButton-root .MuiTypography-root {
  font-size: 14px !important;
}
.sideDrawer-list .subMenuList {
  padding: 8px;
}
.active {
  background-color: #d6e0ff69 !important;
}

/* datagrid style */
.MuiDataGrid-columnHeaders {
  box-shadow: 0px 0px 6px #dedede;
  border-bottom: none !important;
  color: #686868;
}
.MuiDataGrid-root .MuiDataGrid-row {
  color: #323232bf;
}
.MuiDataGrid-root .MuiDataGrid-row:hover,
.MuiDataGrid-root .MuiDataGrid-row.Mui-hovered {
  background-color: rgb(241 244 255) !important;
  color: #323232;
}
.MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: var(--theme-color) !important;
}
.MuiButtonBase-root.MuiFab-root {
  background-color: var(--theme-color) !important;
  color: #fff;
  z-index: 5;
}
.MuiButtonBase-root.MuiFab-root:hover {
  background-color: var(--theme-color) !important;
}
.MuiTab-root.Mui-selected {
  color: var(--theme-color) !important;
}
.MuiTabs-indicator {
  background-color: var(--theme-color) !important;
}
.MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--theme-color) !important;
}
.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
  color: var(--theme-color) !important;
}
.MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--theme-color) !important;
}
.MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
  background-color: var(--theme-color-light) !important;
}
.MuiButtonBase-root.MuiMenuItem-root:hover {
  background-color: rgb(241 244 255) !important;
}
.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
  max-height: 300px !important;
}
.MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
  background-color: var(--theme-color) !important;
}
.MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-today:not(.Mui-selected) {
  border: 1px solid var(--theme-color);
}
.MuiButtonBase-root.MuiRadio-root.Mui-checked {
  color: var(--theme-color) !important;
}
.MuiPaper-root::-webkit-scrollbar,
.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper::-webkit-scrollbar,
.MuiAutocomplete-popper .MuiAutocomplete-listbox::-webkit-scrollbar,
.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
}

.MuiPaper-root::-webkit-scrollbar-track,
.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper::-webkit-scrollbar-track,
.MuiAutocomplete-popper .MuiAutocomplete-listbox::-webkit-scrollbar-track,
.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

.MuiPaper-root::-webkit-scrollbar-thumb,
.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper::-webkit-scrollbar-thumb,
.MuiAutocomplete-popper .MuiAutocomplete-listbox::-webkit-scrollbar-thumb,
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background: var(--theme-color) !important;
}
/* MuiAutocomplete-popper */

/* accordion style start*/
.MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded {
  min-height: 48px !important;
  border-bottom: 1px solid #32323226;
  background: #dda3011c;
}
.MuiAccordionSummary-content,
.MuiAccordionSummary-content.Mui-expanded {
  margin: 0 !important;
  align-items: center;
}
/* accordion style end*/
.MuiBottomNavigation-root {
  height: 54px !important;
}
.MuiButtonBase-root.MuiBottomNavigationAction-root.Mui-selected {
  color: #00000099;
}

/* addon chips hover style */
.MuiChip-root.MuiChip-clickable:hover {
  background-color: var(--theme-color) !important;
  color: #fff;
  border-color: var(--theme-color);
}

.SnackbarItem-variantInfo {
  background-color: var(--theme-color) !important;
  max-width: 324px;
}
.SnackbarContainer-bottom {
  bottom: 74px !important;
}
.SnackbarItem-action {
  position: absolute;
  top: 0;
  right: 16px;
}
/* dropdown style for dimension select */
div#demo-select-small-select {
  display: flex;
  align-items: center;
}

.gm-ui-hover-effect:focus-visible {
  outline: none !important;
}

/* confirmation dialog */
.confirmationDialog .MuiPaper-root.MuiDialog-paper {
  background: #ffdddd;
  color: #ff1100;
  /* f44336 */
}
.confirmationDialog .MuiButtonBase-root.MuiButton-root {
  color: #fff;
  font-weight: 600;
  background: #ff0000;
  border-radius: 33px;
}

:root {
  /* --theme-color-dark: #dda301;
  --theme-color: #f0b81b;
  --theme-color-light: rgb(240 184 27 / 10%);
  --theme-color-light: #fdf1cf; */
  --theme-color-dark: #040707;
  --theme-color: #040707;
  --theme-color-light: #d6e0ff;
  --black: #040707;
}


    @media screen and (max-width: 425px) {
    .textContainerdep h5 {
     text-align:center;
     font-size: 20px !important;
     padding-top:10px;
     text-align: left;
    } 
    .buttonContainerdep button {
      text-align: center;
      padding-top:10px;
      font-size: 14px !important; 
    }
    }
    @media screen and (max-width: 375px) {
     
      .textContainerdep h5 {
       text-align:center; 
       font-size: 18px !important;
       padding-top:10px;
        text-align: left;
      } 
      .buttonContainerdep button {
        text-align: center;
        font-size: 12px !important; 
        padding-top:7px;
      }   
    }
      @media screen and (max-width: 320px) {
     
        .textContainerdep h5 {
         text-align:center; 
         font-size: 16px !important;
         padding-top:10px;
          text-align: left;
        } 
        .buttonContainerdep button {
          text-align: center;
          font-size: 10px !important; 
          padding-top:7px;
        }   
       
    }


    @media (max-width:375px){
     .lableContainer{
       font-size:21px !important;
     }
   }
     @media (max-width:320px){
       .lableContainer{
         font-size:17px !important;
       }
     }

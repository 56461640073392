.accordionPaper {
  border-radius: 4px !important;
}

.checkbox {
  padding: 0px !important;
  margin: 0px 9px !important;
}

.rightsList {
  display: flex;
  flex-wrap: wrap;
}
.rightsList li {
  width: 100% !important;
}
.otherRightsContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 60%;
}
.otherRightsText {
  font-size: 14px;
  color: #999;
}
/*---------- SubMenu Accordion -------------*/
/* .SubMenuaccordionPaper {
  border-radius: 0px !important;
  box-shadow: none !important;
  width: 100%;
  padding: 0px;
  min-height: 32px;
}
.SubMenuaccordionSummary {
  background: transparent !important;
  padding-left: 0 !important;
  margin-left: -8px;
} */


    @media (max-width: 425px) {
     .title {
       font-size: 23px !important; 
       text-align:center;
     }
   }
    @media (max-width: 375px) {
     .title {
       font-size: 19px !important; 
       text-align:center;
     }
   }
     @media (max-width: 320px) {
       .title {
         font-size: 15px !important; 
         text-align:center;
       }
     }

